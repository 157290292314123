<template>
  <v-card
    :style="
      'height: 100%; overflow: hidden;' +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard
        : '')
    "
  >
    <ChartControl
      v-on:update-ranges="newData"
      :enabled="settingsEnabled"
      :loading="loading"
      :module="module"
      :titleTextColor="soMetaTextColorTitle"
    />
    <v-row
      no-gutters
      :style="fakeData != false ? 'height: 300px' : 'height: 90%'"
    >
      <e-chart
        v-if="opts.series.length > 0"
        :option="opts"
        style="width: 100%; max-width: 100%; min-height: 100%; max-height: 100%"
        :autoresize="true"
        :theme="this.darkmode ? 'sensoronlinedark' : 'macarons'"
      />
    </v-row>
  </v-card>
</template>

<script>
import ChartControl from "@/components/chart/ChartControl";
import { mapActions, mapState } from "vuex";
import "echarts/lib/coord/cartesian/Grid";

import Meta from "@/_helpers/ModuleMeta";
import i18n from "@/plugins/i18n";
import { materialColors } from "@/_helpers/materialDesignColors";

export default {
  name: "Multichart",

  mixins: [Meta],

  props: {
    settingsEnabled: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    module: {
      default: {},
    },
    tag: {
      default: null,
    },
    timerTick: {
      default: null,
    },
    fakeData: {
      default: false,
    },
  },

  data() {
    return {
      startDate: new Date(new Date().setHours(new Date().getHours() - 24)),
      endDate: new Date(),
      digital: false,
      axisSync: false,
      axisLabel: null,
      dataZoom: false,
      boundAlarm: null,
      alarmHigh: null,
      alarmLow: null,
      xAxisLimits: {
        min: null,
        max: null,
      },
      chartType: "area",
      colors: ["#5470C6", "#91CC75", "#EE6666"],
      opts: {
        legend: {
          show: true,
          data: [],
        },
        grid: {
          top: 55,
          bottom: 50,
          left: 80,
          right: 80,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        xAxis: {
          type: "time",
          splitLine: {
            show: false,
          },
        },
        yAxis: [],
        dataZoom: [
          {
            show: true,
            type: "inside",
            throttle: 50,
          },
        ],
        series: [],
      },
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
    ...mapState("alarms", ["alarms"]),
  },

  methods: {
    ...mapActions("tagData", ["getTagDataForModule"]),
    ...mapActions("alarms", ["getAlarms"]),

    async updateData() {
      let to = new Date();
      let data = await this.getTagDataForModule({
        moduleId: this.module.dashboardModuleId,
        metrics: {
          from: this.toApiDate(this.endDate),
          to: this.toApiDate(to),
        },
      });

      if (data != undefined && data.length > 0) {
        data.forEach((d) => this.setData(d.fieldName, d.value, d.date));
        this.endDate = to;
      }
    },

    async newData(metrics) {
      if (metrics == undefined) return;

      let data = await this.getTagDataForModule({
        moduleId: this.module.dashboardModuleId,
        metrics: {
          from: metrics.from,
          to: metrics.to,
        },
      });

      if (data != undefined && data.length > 0) {
        this.opts.series = [];
        this.opts.legend.data = [];
        this.chartType != "vertical"
          ? (this.opts.yAxis = [])
          : (this.opts.xAxis = []);
        this.opts.grid = {
          top: 55,
          bottom: 50,
          left: 80,
          right: 80,
        };

        data.forEach((d) => this.setData(d.fieldName, d.value, d.date));

        this.startDate = new Date(metrics.from);
        this.endDate = new Date(metrics.to);

        this.updateColors();
        this.setAlarmSeries();
        await this.setTargetMetas();
      }
    },

    setData(name, value, date, fieldID) {
      // Check if fieldname exist in series
      for (var i in this.opts.series) {
        if (this.opts.series[i].name == name) {
          if (!this.opts.legend.data.includes(name))
            this.opts.legend.data.push(name);

          this.opts.series[i].data.push({
            name: new Date(date).toString(),
            value: this.chartType == "vertical" ? [value, date] : [date, value],
          });
          return;
        }
      }

      let series = {
        name: name,
        fieldID: fieldID ? fieldID : null,
        type:
          this.chartType == "area" || this.chartType == "vertical"
            ? "line"
            : this.chartType,
        data: [],
        areaStyle: { opacity: 0.2 },
        itemStyle: {},
        lineStyle: {},
        step: false,
        markLine: { data: [] },
      };

      if (this.chartType == "line") series.areaStyle = null;

      if (this.digital) {
        series.step = "end";
        series.symbolSize = 6;
        series.lineStyle.width = 4;
        series.smooth = false;
      }

      if (this.axisSync && this.chartType != "vertical") {
        this.addSideScale(name);
        series.yAxisIndex = this.opts.series.length;
      } else if (!this.axisSync && this.opts.yAxis.length == 0)
        this.opts.yAxis.push({
          type: "value",
          min: this.xAxisLimits.min ? this.xAxisLimits.min : undefined,
          max: this.xAxisLimits.max ? this.xAxisLimits.max : undefined,
          name: this.axisLabel ? this.axisLabel.value : undefined,
        });

      this.opts.series.push(series);
    },

    addSideScale(name) {
      // Check if fieldname already has an y-axis
      for (var i in this.opts.yAxis)
        if (this.opts.yAxis[i].name == name) return;

      let curLen = this.opts.yAxis.length;

      this.opts.yAxis.push({
        type: "value",
        name: name.split(" ").pop(),
        position: curLen > 0 ? "right" : "left",
        offset: curLen > 1 ? (curLen - 1) * 70 : 4,
        nameLocation: (curLen - 1) % 2 == 1 ? "start" : "end",
        axisLine: {
          show: true,
          lineStyle: {
            width: 2,
          },
        },
        axisLabel: {
          show: true,
        },
      });

      // Make space for the new scale
      this.opts.grid.right += 10 + 16 * curLen;
    },

    addDataZoom() {
      this.opts.dataZoom.push({
        type: "slider",
        show: true,
        height: 50,
        bottom: 25,
        xAxisIndex: [0],
      });
      this.opts.grid.bottom += 45;
    },

    async setTargetSeries(value) {
      var series = {
        type: "line",
        data: [],
        itemStyle: { color: "#33691e" },
        z: 9998,
        markLine: {
          data:
            this.chartType == "vertical"
              ? [{ xAxis: value }]
              : [{ yAxis: value }],
          silent: true,
          lineStyle: { width: 3 },
          symbolSize: 10,
          symbol: ["arrow", "arrow"],
          label: {
            fontWeight: "bold",
            position: "middle",
            formatter: "{c} | " + i18n.t("module.meta.fields.target"),
          },
        },
      };

      this.opts.series.push(series);
    },

    setAlarmSeries() {
      if (this.boundAlarm == null) return;

      if (this.boundAlarm.limitHigh) {
        this.alarmHigh = {
          name: i18n.t("alarm.fields.limitHigh") + " - " + this.boundAlarm.name,
          type: "line",
          data: [],
          z: 9999,
          itemStyle: { color: "#b71c1c" },
          markLine: {
            data:
              this.chartType == "vertical"
                ? [
                    {
                      xAxis: this.boundAlarm.limitHigh,
                    },
                  ]
                : [
                    {
                      yAxis: this.boundAlarm.limitHigh,
                    },
                  ],
            silent: false,
            lineStyle: { width: 3 },
            symbolSize: 10,
            symbol: ["arrow", "arrow"],
            label: {
              fontWeight: "bold",
              position: "middle",
              formatter:
                "{c} | " +
                i18n.t("alarm.fields.limitHigh") +
                " - " +
                this.boundAlarm.name,
            },
          },
        };
      }

      if (this.boundAlarm.limitLow) {
        this.alarmLow = {
          name: i18n.t("alarm.fields.limitLow") + " - " + this.boundAlarm.name,
          type: "line",
          data: [],
          z: 9999,
          itemStyle: { color: "#0d47a1" },
          markLine: {
            data:
              this.chartType == "vertical"
                ? [
                    {
                      xAxis: this.boundAlarm.limitHigh,
                    },
                  ]
                : [
                    {
                      yAxis: this.boundAlarm.limitHigh,
                    },
                  ],
            silent: false,
            lineStyle: { width: 3 },
            symbolSize: 10,
            symbol: ["arrow", "arrow"],
            label: {
              fontWeight: "bold",
              position: "insideMiddleTop",
              formatter:
                "{c} | " +
                i18n.t("alarm.fields.limitLow") +
                " - " +
                this.boundAlarm.name,
            },
          },
        };
      }

      this.opts.series.push(this.alarmHigh);
      this.opts.series.push(this.alarmLow);
    },

    async setMetaParams() {
      if (!this.module.meta) return;

      let chartType = await this.getMeta(
        this.module.meta,
        Meta.Enum.CHART_TYPE
      );
      if (chartType && chartType.value) {
        this.chartType = chartType.value;
      } else {
        this.chartType = "area";
      }

      let digital = await this.getMeta(this.module.meta, Meta.Enum.DIGITAL);
      if (digital) this.digital = digital.value === "true";

      if (this.chartType != "vertical") {
        let sync = await this.getMeta(this.module.meta, Meta.Enum.SYNC_Y);
        if (sync) this.axisSync = sync.value === "true";

        let zoomSlider = await this.getMeta(
          this.module.meta,
          Meta.Enum.ZOOM_SLIDER
        );
        if (zoomSlider) this.zoomSlider = zoomSlider.value === "true";
      }

      let boundAlarm = await this.getMeta(this.module.meta, Meta.Enum.ALARM_ID);
      if (boundAlarm) {
        this.boundAlarm = this.alarms.find(
          (a) => a.alarmId == boundAlarm.valueMatch
        );
      }

      let minVal = await this.getMeta(this.module.meta, Meta.Enum.MIN);
      if (minVal) this.xAxisLimits.min = parseFloat(minVal.value);
      let maxVal = await this.getMeta(this.module.meta, Meta.Enum.MAX);
      if (maxVal) this.xAxisLimits.max = parseFloat(maxVal.value);

      this.axisLabel = await this.getMeta(
        this.module.meta,
        Meta.Enum.AXIS_LABEL
      );

      let timeSpanMeta = await this.getMeta(
        this.module.meta,
        Meta.Enum.DEFAULT_TIMESPAN
      );

      if (timeSpanMeta) {
        switch (timeSpanMeta.value) {
          case "30_days":
            this.startDate = new Date(new Date() - new Date(0).setUTCDate(30));
            break;
          case "7_days":
            this.startDate = new Date(new Date() - new Date(0).setUTCDate(7));
            break;
          case "24_hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(24));
            break;
          case "5_hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(5));
            break;
          case "hour":
            this.startDate = new Date(new Date() - new Date(0).setUTCHours(1));
            break;
        }
      }
    },

    async setTargetMetas() {
      if (!this.module.meta) return;

      let targetMetas = this.module.meta.filter((m) =>
        m.key.startsWith("so_target")
      );

      if (targetMetas && targetMetas.length > 0)
        for (let target in targetMetas)
          await this.setTargetSeries(targetMetas[target].valueMatch);
    },

    updateColors() {
      if (!this.module.meta) return;

      let styleMetas = this.module.meta.filter((m) =>
        m.key.startsWith("so_style_meta")
      );

      for (let j in this.opts.series) {
        let color = null;

        for (let i in styleMetas) {
          if (
            styleMetas[i].valueMatch &&
            this.opts.series[j].fieldID &&
            styleMetas[i].valueMatch == this.opts.series[j].fieldID
          )
            color = styleMetas[i].content;
          else if (
            styleMetas[i].value &&
            this.opts.series[j].name &&
            styleMetas[i].value == this.opts.series[j].name
          )
            color = styleMetas[i].content;
        }

        if (color == null) color = materialColors[j];

        this.opts.series[j].itemStyle.color = color;

        if (this.chartType == "area")
          this.opts.series[j].areaStyle = {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: color + "C0",
                },
                {
                  offset: 1,
                  color: color + "08",
                },
              ],
              global: false,
            },
          };

        if (this.axisSync && this.opts.yAxis[j])
          this.opts.yAxis[j].axisLine.lineStyle.color = color;
      }
    },

    setFakeData(name) {
      var base = +new Date(1988, 9, 3);
      var oneDay = 24 * 3600 * 1000;
      var lastVal = Math.floor(Math.random() * 3);

      for (var i = 1; i < 10; i++) {
        var now = new Date((base += oneDay));
        let d = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join(
          "/"
        );
        let val = Math.round(
          (Math.random() - 0.1) *
            (this.xAxisLimits.max ? this.xAxisLimits.max / 5 : 60) +
            lastVal
        );
        this.setData(name, val, d);
        lastVal = val;
      }
    },

    async setupFakeData() {
      this.opts.series = [];
      this.opts.legend.data = [];
      this.opts.yAxis = [];
      this.opts.grid = {
        top: 55,
        bottom: 50,
        left: 80,
        right: 80,
      };
      // Add fake data
      let ss = [];
      if (
        this.module.meta != undefined &&
        this.module.meta.filter((m) => m.key == "so_style_meta_color").length >
          0
      ) {
        for (var j in this.module.meta) {
          let m = this.module.meta[j];
          if (m.key == "so_style_meta_color") {
            ss.push(
              this.module.meta[j].value == ""
                ? "Device - Sensor " + j
                : this.module.meta[j].value
            );
          }
        }
      } else {
        ss.push("Device - Sensor 1");
      }

      await this.setMetaParams();
      for (var j in ss) {
        this.setFakeData(ss[j]);
      }

      this.updateColors();
    },

    flipAxes() {
      let kAxis = this.opts.xAxis;
      this.opts.xAxis = this.opts.yAxis;
      this.opts.yAxis = kAxis;
    },
  },

  async created() {
    if (!this.fakeData) {
      let storedTimeOffset = this.getModuleSettingsTimeOffset(
        this.module.dashboardModuleId
      );

      if (storedTimeOffset != undefined) {
        this.startDate = new Date(new Date() - storedTimeOffset);
        this.endDate = new Date();
      }

      await this.setMetaParams();

      let data = await this.getTagDataForModule({
        moduleId: this.module.dashboardModuleId,
        metrics: {
          from: this.startDate.toISOString(),
          to: this.endDate.toISOString(),
        },
      });

      if (data != undefined) {
        data.forEach((d) =>
          this.setData(d.fieldName, d.value, d.date, d.fieldID)
        );
      }

      if (this.boundAlarm != null) {
        this.getAlarms();
        this.setAlarmSeries();
      }
    } else {
      await this.setupFakeData();
    }

    if (this.zoomSlider) this.addDataZoom();

    this.updateColors();

    await this.setTargetMetas();

    if (this.chartType == "vertical") {
      this.flipAxes();
      this.opts.yAxis.inverse = true;
    }
  },

  components: {
    ChartControl,
  },

  watch: {
    timerTick(v) {
      if (v % 6 == 0) {
        this.updateData();
      }
    },

    async module() {
      if (this.fakeData && this.module.meta && this.module.meta.length > 0) {
        if (this.chartType == "vertical") this.flipAxes();

        await this.setupFakeData();

        if (this.chartType == "vertical") this.flipAxes();
      }
    },
  },
};
</script>